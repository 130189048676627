exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-yumma-tsx": () => import("./../../../src/pages/about-yumma.tsx" /* webpackChunkName: "component---src-pages-about-yumma-tsx" */),
  "component---src-pages-enquire-tsx": () => import("./../../../src/pages/enquire.tsx" /* webpackChunkName: "component---src-pages-enquire-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

